import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";

import * as Sentry from "@sentry/react";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { LAUNCH_DARKLY_CONFIG } from "./config/launchdarkly.config";

Sentry.init({
  dsn: "https://f69410d5d46ccb391fa1e7779c3ccbc0@o4504611806511104.ingest.us.sentry.io/4506917306826752",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const LDProvider = withLDProvider(LAUNCH_DARKLY_CONFIG)(App);

ReactDOM.createRoot(document.getElementById("root")!).render(<LDProvider />);
