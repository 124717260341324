import { MAX_SIZE_MIO } from "@components/layout/Messaging";
import { t } from "@lingui/macro";
import { useDiscussion } from "@provider/discussion.provider";
import { useNotify } from "@util/notify";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Loader } from "../Loader";
import {
  DisplayImage,
  ImageStyleProps,
} from "@components/fundamental/DisplayImage/DisplayImage.fundamental";
import { PlusSquareFill } from "react-bootstrap-icons";
import styled from "styled-components";
import { FlexColumnLayout } from "reonelabs-ui";

type UploadImageProps = {
  onUpload: (image: string) => void;
  style?: ImageStyleProps;
  defaultImage: string | null;
  uploadHeight?: string;
};

/**
 * A component that displays an upload button and a file input field.
 * When the button is clicked, the file input field is opened for file selection.
 * When a file is selected, it's sent to the server and the component is updated with the response.
 * If the image is too large, an error message is displayed.
 * The component is also updated with the default image if it's provided.
 *
 * @param {UploadImageProps} props - The props of the component.
 * @returns {JSX.Element} The rendered component.
 */
export function UploadImage({ onUpload, style, defaultImage, uploadHeight }: UploadImageProps) {
  const fileInputRef = useRef(null);
  const notify = useNotify();
  const { uploadImageToCDN } = useDiscussion();

  const [image, setImage] = useState<string | null>(defaultImage);

  const handleButtonClick = () => {
    if (!fileInputRef.current) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore click function always exists
    fileInputRef.current.click();
  };

  async function sendImage(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      notify.error(t`Aucune image sélectionnée`);
      return;
    }

    const file = event?.target?.files[0];
    /**
     * Checks if the image file is too large. If it is, it displays an error message and returns.
     */
    if (file.size >= MAX_SIZE_MIO) {
      notify.error(t`Image trop volumineuse`);
      return;
    }
    const reader = new FileReader();

    /**
     * Handles the onloadend event of the FileReader, converting the image file to a base64 string and emitting an "image" event with the tattooId, clientId, message, image, and createdAt.
     *
     * @param {Event} event - The onloadend event triggered by the FileReader.
     * @return {void} This function does not return anything.
     */
    reader.onloadend = async () => {
      const base64Image = reader.result as string;

      uploadImageToCDN.mutate(base64Image, {
        onSuccess: (response) => {
          setImage(response.data.public_id);
          onUpload(response.data.public_id);
        },
      });
    };

    reader.readAsDataURL(file);
  }

  useEffect(() => {
    if (defaultImage) {
      setImage(defaultImage);
    }
  }, [defaultImage]);

  if (uploadImageToCDN.isPending) {
    return <Loader />;
  }
  return (
    <>
      {!image ? (
        <PictureStepContainer
          $justifyContent="center"
          $alignItems="center"
          $gap={1.25}
          onClick={handleButtonClick}
          style={{ height: uploadHeight }}
        >
          <PlusSquareFill size={24} color="white" />
        </PictureStepContainer>
      ) : (
        <DisplayImage imageID={image} style={{ maxWidth: "100%", maxHeight: "100%", ...style }} />
      )}

      <input type="file" onChange={sendImage} ref={fileInputRef} style={{ display: "none" }} />
    </>
  );
}

const PictureStepContainer = styled(FlexColumnLayout)`
  box-sizing: border-box;
  border-radius: var(--Border-Radius-rounded, 4px);
  height: 100%;
  background: var(--Layout-Nav-Text, #a3a3a3);
  padding: 0px var(--spacings-520-px-125-rem, 20px);
`;
